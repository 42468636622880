import React, { Fragment } from "react";
import Routes from "./router";
import "bootstrap/dist/css/bootstrap.min.css";
// import "../node_modules/toastr/build/toastr.css";
// import "~slick-carousel/slick/slick.css";
// import "../node_modules/slick-carousel/";
// import "../node_modules/";
// import "~slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as Ably from "ably";
import { AblyProvider, ChannelProvider } from "ably/react";
import { MeetingAppProvider } from "./components/azure-video/MeetingAppContextDef";
const App = () => {
  const client = new Ably.Realtime({
    key: "MnLrtg.SgE_JQ:gdNMGCjfgbEQULQsra39cLc1E2YxjfWi_OH4YisD5is",
  });

  return (
    <Fragment>
      <ToastContainer theme="colored" />
      <MeetingAppProvider>
      <AblyProvider client={client}>
        <ChannelProvider channelName="get-started">
          <Routes />
        </ChannelProvider>
      </AblyProvider>
      </MeetingAppProvider>

    </Fragment>
  );
};

export default App;
